<template>
  <div class="view-login">
    <p>Acesse sua conta</p>

    <form @submit.prevent="submit">
      <form-group
        v-model="form.email"
        id="email"
        label="E-mail"
        type="email"
        icon="fa-regular fa-user"
        placeholder="Digite seu e-mail"
        :errors="errors.email"
        @setError="setError"
        autoCheck
        ref="formEmail"
      />

      <form-group
        v-model="form.password"
        id="password"
        label="Senha"
        type="password"
        class="mb-0"
        icon="fa-regular fa-search"
        placeholder="Digite sua senha"
        :errors="errors.password"
        @setError="setError"
        autoCheck
        ref="formPassword"
      />

      <p class="text-right m-0">
        <router-link :to="{ name: 'forgot-password' }" class="forgot-password">
          Esqueci minha senha
        </router-link>
      </p>

      <auth-button
        text="Acessar sua conta"
        class="text-center pt-5 mb-3"
        :loading="loading"
        :disabled="loading"
        @click="submit"
      />
    </form>

    <hr />

    <div class="footer">
      Este site é protegido por reCAPTCHA e a
      <a target="_blank" href="https://policies.google.com/privacy"
        >Política de Privacidade</a
      >
      e os
      <a target="_blank" href="https://policies.google.com/terms"
        >Termos de Serviço</a
      >
      do Google se aplicam.
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/auth/AuthButton.vue";
import { formFields } from "@/functions/helpers.js";

export default {
  name: "login",
  components: { AuthButton },
  data() {
    return {
      ...formFields(["email", "password"]),
      loading: false,
      forgotDialog: false
    };
  },
  methods: {
    setError(error, field) {
      this.errors[field] = error;
    },
    submit() {
      this.$message.hide();
      this.loading = true;
      this.$refs.formEmail.blur();
      this.$refs.formPassword.blur();

      window.grecaptcha
        .execute({ action: "submit" })
        .then(token => {
          this._submit(token);
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("Por favor, tente novamente");
        });
    },
    _submit(recaptcha_token) {
      this.$store
        .dispatch("user/login", {
          ...this.form,
          recaptcha_token
        })
        .then(() => {
          this.$router.push({ name: "home", params: { first_access: true } });
        })
        .catch(error => {
          if (error.response.data.message)
            this.$message.error(error.response.data.message[0]);

          Object.keys(error.response.data).forEach(field => {
            if (!this.errors[field])
              this.errors[field] = error.response.data[field];
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  hr {
    width: 25%;
    margin-bottom: 32px;
    margin-top: 48px;
    border-top: 2px solid var(--semantic-neutral-700, #d5d5d5);
  }
  .forgot-password {
    font-size: 14px;
    color: var(--semantic-neutral-600, #c7c7c7);
  }
  .footer {
    margin-top: 50px;
    text-align: center;
    font-size: 14px;
    color: var(--semantic-neutral-600, #c7c7c7);
    a {
      color: #fff;
    }
  }
}
</style>
